// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.splash-animation-container {
  height: 50vh;
}

.splash-title-container {
  font-size: 2.5rem;
  text-decoration: none;
}
.splash-title {
  font-family: "Edu AU VIC WA NT Pre", cursive;
  font-weight: normal;
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 20px;
}
`, "",{"version":3,"sources":["webpack://./src/containers/splashScreen/SplashScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;AACA;EACE,4CAA4C;EAC5C,mBAAmB;EACnB,yCAA2C;EAA3C,2CAA2C;EAC3C,mDAAmD;EACnD,eAAe;AACjB","sourcesContent":[".splash-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n}\n\n.splash-animation-container {\n  height: 50vh;\n}\n\n.splash-title-container {\n  font-size: 2.5rem;\n  text-decoration: none;\n}\n.splash-title {\n  font-family: \"Edu AU VIC WA NT Pre\", cursive;\n  font-weight: normal;\n  font-variant-ligatures: no-common-ligatures;\n  -webkit-font-variant-ligatures: no-common-ligatures;\n  padding: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
